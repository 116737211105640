import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from 'react-grid-system';
import { HeroPatients } from 'components/hero';
import { Link } from 'gatsby';

const ContactUsPage = () => {
	return (
		<Layout indication='patient' className='patient-contact-us'>
			<Seo pageTitle='Contact Us' />
			<div className='body-content-container'>
				<div className='body-content'>
					<HeroPatients heroImgAlt='page hero' className='sub-page-hero'>
						<h1>Contact Us</h1>
					</HeroPatients>
					<section>
						<Row align='center'>
							<Col xs={12}>
								<p>
									<span className='text-bold'>Main Phone Number:</span>{' '}
									203-588-8000
								</p>
								<p>
									<span className='text-bold'>
										Medical Inquiries/Drug Safety:
									</span>{' '}
									1-888-726-7535
								</p>
								<p>
									<span className='text-bold'>Customer Service:</span>{' '}
									1-800-877-5666
								</p>
								<p>
									<span className='text-bold'>
										Public Affairs/Media Relations:
									</span>{' '}
									203-588-8069
								</p>
								<p>
									<span className='text-bold'>
										Ethics & Compliance Hotline: 1-877-PURDUE1 (or
										1-877-787-3831) toll-free
									</span>
									, 24 hours a day, to report any suspected violations of
									ethics, law or Purdue policy. Callers may choose to remain
									anonymous and will be protected from retaliation in any form.
									Alternatively, callers may email Purdue Ethics & Compliance at{' '}
									<a href='mailto:compliance@pharma.com'>
										compliance@pharma.com
									</a>
									.
								</p>
							</Col>
						</Row>
					</section>
				</div>
			</div>
		</Layout>
	);
};

export default ContactUsPage;
